import React, { useState, useEffect } from 'react'

//css
// import './styles.css'

//Service
import rest from '../../service/rest'

//Components
import MenuBar from '../../components/menuBar'
import Footer from '../../components/footer'
import LoadingBar from '../../components/loadingBar'
import AlertMessage from '../../components/alertMessage'
import FieldText from '../../components/fieldText'
import ButtonRectangle from '../../components/buttonRectangle'
import IconCard from '../../components/iconCard'
import FieldTextOpacity from '../../components/fieldTextOpacity'
import OverMessage from '../../components/overMessage'

//PrimeReact
import { Chart } from 'primereact/chart';

//Other
import { CSVLink, CSVDownload } from 'react-csv'
import TableFilter from 'react-table-filter'

export default () => {
    const [isLoading, setIsLoading] = useState(false)
    const [citys, setCitys] = useState()
    const [filterData, setFilterData] = useState()
    const [selectedCity, setSelectedCity] = useState()

    const [overMessage, setOverMessage] = useState({
        show: false,
        message: '',
        type: ''
    })

    const findCitys = async () => {
        const response = await rest.get('/city/v1/findAll')
        setCitys(response.data)
    }

    const registerCity = async () => {
        let city = document.getElementById("edtCity").value
        let uf = document.getElementById("edtUf").value
        if (city == '' || uf == '') {
            setOverMessage({
                show: true,
                message: 'Favor informar cidade e UF',
                type: 'warning'
            })
            setTimeout(() => {
                setOverMessage({
                    show: false,
                    message: '',
                    type: ''
                })
            }, 4000)
        } else {
            try {
                setIsLoading(true)
                setOverMessage({
                    show: false,
                    message: '',
                    type: ''
                })

                const response = await rest.post('/city/v1/save', {
                    name: city,
                    uf: uf
                })

                document.getElementById("edtCity").value = ''
                document.getElementById("edtUf").value = ''
                await findCitys()
                setIsLoading(false)
                setOverMessage({
                    show: true,
                    message: 'Cidade cadastrada com sucesso',
                    type: 'success'
                })
                setIsLoading(false)
                setTimeout(() => {
                    setOverMessage({
                        show: false,
                        message: '',
                        type: ''
                    })
                }, 3000)
            } catch (err) {
                setOverMessage({
                    show: true,
                    message: 'Falha ao registrar a cidade',
                    type: 'error'
                })
                setIsLoading(false)
            }
        }
    }

    const pressEnter = async (e) => {
        if (e.key == 'Enter')
            registerCity()
    }

    useEffect(() => {
        translateSearch()
        findCitys()
    }, [])

    const translateSearch = () => {
        let eSrarchs = document.getElementsByClassName("search-input");
        let i;
        for (i = 0; i < eSrarchs.length; i++) {
            eSrarchs[i].placeholder = "Procurar...";
        }
    }


    const updateCity = async () => {
        let city = document.getElementById("edtCityUpdate").value
        let uf = document.getElementById("edtUfUpdate").value
        if (city == '' || uf == '') {
            setOverMessage({
                show: true,
                message: 'Favor informar cidade e UF',
                type: 'warning'
            })
            setTimeout(() => {
                setOverMessage({
                    show: false,
                    message: '',
                    type: ''
                })
            }, 4000)
        } else {
            try {
                setIsLoading(true)
                setOverMessage({
                    show: false,
                    message: '',
                    type: ''
                })

                if (selectedCity.id > 0) {
                    const response = await rest.post('/city/v1/update', {
                        id: selectedCity.id,
                        name: city,
                        uf: uf
                    })

                    // document.getElementById("edtCityUpdate").value = ''
                    // document.getElementById("edtUfUpdate").value = ''
                    await findCitys()
                    document.getElementById('btnCloseModalId').click()
                    setOverMessage({
                        show: true,
                        message: 'Cidade alterada com sucesso',
                        type: 'success'
                    })
                    setIsLoading(false)
                    setTimeout(() => {
                        setOverMessage({
                            show: false,
                            message: '',
                            type: ''
                        })
                    }, 3000)
                } else {
                    setOverMessage({
                        show: false,
                        message: 'Favor selecionar uma cidade',
                        type: 'warning'
                    })
                }
                setIsLoading(false)

            } catch (err) {
                setOverMessage({
                    show: true,
                    message: 'Falha ao registrar a cidade',
                    type: 'error'
                })
                setIsLoading(false)
            }
        }
    }

    const changeSelectedCity = (item) => {
        setSelectedCity(item)
    }

    useEffect(() => {
        translateSearch()
        setSelectedCity(null)
    }, [citys])

    const filterUpdated = (newData, filterConfiguration) => {
        // setFilterData(newData)
        setCitys(newData)
    }

    return (
        <div>
            {
                isLoading && <LoadingBar />
            }
            <div id="top">
                <MenuBar />
            </div>
            <OverMessage show={overMessage.show} message={overMessage.message} type={overMessage.type} />
            <div className="full padding100Top container">
                <div className="row">
                    <div className="offset-lg-4 col-12 col-lg-10">
                        <div className="row">
                            <div className="col-12 col-md-3 top10" onKeyDown={e => pressEnter(e)}>
                                <FieldText id="edtCity" name="edtCity" placeholder="Cidade" />
                            </div>
                            <div className="col-12 col-md-3 top10" onKeyDown={e => pressEnter(e)}>
                                <FieldText id="edtUf" name="edtUf" placeholder="UF" />
                            </div>
                            <div className="col-12 col-md-3 top10">
                                <div onClick={e => registerCity()}>
                                    <ButtonRectangle label="Cadastrar" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row top40">
                    <div className="col-12">
                        {
                            citys && <CSVLink
                                filename='arquivo.csv'
                                className="btn btn-primary mainColorBk"
                                data={citys}>
                                Download
                            </CSVLink>
                        }
                    </div>
                    <div className="col-12">
                        <table className="table boxShadow table-hover borderRadius10">
                            <thead>
                                {
                                    citys &&
                                    <TableFilter
                                        rows={citys}
                                        onFilterUpdate={filterUpdated}
                                    >
                                        <th key="id" filterkey="id" className="cell" casesensitive={'true'} showsearch={'true'}>
                                            Código
                                        </th>
                                        <th filterkey="name">
                                            Cidade
                                        </th>
                                        <th filterkey="uf">
                                            UF
                                        </th>
                                        <th></th>
                                    </TableFilter >
                                }
                            </thead>
                            <tbody>
                                {
                                    citys &&
                                    citys.map((item, key) => (
                                        <tr key={key}>
                                            <td>{item.id}</td>
                                            <td>{item.name}</td>
                                            <td>{item.uf}</td>
                                            <td>
                                                <button data-toggle="modal" data-target='#updateCityModal' className='btn btn-primary mainColorBk' onClick={(e) => changeSelectedCity(item)}>Editar</button>
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                    <div class="modal fade" id='updateCityModal' tabindex="-1" role="dialog" aria-labelledby="updateCityModal" aria-hidden="true">
                        <div class="modal-dialog" role="document">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title" id="modalLongTitle">Editar Cidade</h5>
                                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div class="modal-body">
                                    {
                                        selectedCity &&
                                        <div className="row">
                                            {/* <div className="col-12 col-md-3 top10" onKeyDown={e => pressEnter(e)}> */}
                                            <div className="col-12 col-md-3 top10">
                                                <FieldText id="edtCityUpdate" name="edtCityUpdate" placeholder="Cidade" value={selectedCity ? selectedCity.name : ''} />
                                            </div>
                                            {/* <div className="col-12 col-md-3 top10" onKeyDown={e => pressEnter(e)}> */}
                                            <div className="col-12 col-md-3 top10">
                                                <FieldText id="edtUfUpdate" name="edtUfUpdate" placeholder="UF" value={selectedCity ? selectedCity.uf : ''} />
                                            </div>
                                            <div className="col-12 col-md-3 top10">
                                                <div onClick={e => updateCity()}>
                                                    <ButtonRectangle label="Salvar" />
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                                <div class="modal-footer">
                                    <button id='btnCloseModalId' type="button" class="btn btn-secondary" data-dismiss="modal">Fechar</button>
                                    {/* <button type="button" class="btn btn-primary">Save changes</button> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div >
    )
}