import React, { useState, useEffect } from 'react'

//css
import './styles.css'

//Service
import rest from '../../service/rest'

//Components
import MenuBar from '../../components/menuBar'
import Footer from '../../components/footer'
import LoadingBar from '../../components/loadingBar'
import AlertMessage from '../../components/alertMessage'
import FieldText from '../../components/fieldText'
import ButtonRectangle from '../../components/buttonRectangle'
import IconCard from '../../components/iconCard'
import FieldTextOpacity from '../../components/fieldTextOpacity'
import OverMessage from '../../components/overMessage'

//PrimeReact
import { Chart } from 'primereact/chart';

//Other
import { CSVLink, CSVDownload } from 'react-csv'
import TableFilter from 'react-table-filter'
import ReactHTMLTableToExcel from 'react-html-table-to-excel'

export default () => {
    const [isLoading, setIsLoading] = useState(false)
    const [materials, setMaterials] = useState()
    const [materialsUsage, setMaterialsUsage] = useState()
    const [filterData, setFilterData] = useState()
    const [selectedMaterial, setSelectedMaterial] = useState()

    const [overMessage, setOverMessage] = useState({
        show: false,
        message: '',
        type: ''
    })

    const findMaterialsUsage = async () => {
        const response = await rest.get('view/v1/findmaterialUsageView')
        setMaterialsUsage(response.data)
    }

    const findMaterials = async () => {
        const response = await rest.get('material/v1/findAll')
        setMaterials(response.data)
    }

    const registerMaterial = async () => {
        let material = document.getElementById("edtMaterial").value
        let description = document.getElementById("edtDescription").value
        if (material == '') {
            setOverMessage({
                show: true,
                message: 'Favor informar o nome do material',
                type: 'warning'
            })
            setTimeout(() => {
                setOverMessage({
                    show: false,
                    message: '',
                    type: ''
                })
            }, 4000)
        } else {
            try {
                setIsLoading(true)
                setOverMessage({
                    show: false,
                    message: '',
                    type: ''
                })

                const response = await rest.post('/material/v1/save', {
                    name: material,
                    description: description
                })

                document.getElementById("edtMaterial").value = ''
                document.getElementById("edtDescription").value = ''
                await findMaterials()
                setIsLoading(false)

                setOverMessage({
                    show: true,
                    message: 'Material registrado com sucesso',
                    type: 'success'
                })

                setTimeout(() => {
                    setOverMessage({
                        show: false,
                        message: '',
                        type: ''
                    })
                }, 2000)

            } catch (err) {
                setOverMessage({
                    show: true,
                    message: 'Falha na autenticação',
                    type: 'error'
                })
                setIsLoading(false)
            }
        }
    }

    const pressEnter = async (e) => {
        if (e.key == 'Enter')
            registerMaterial()
    }

    useEffect(() => {
        translateSearch()
        findMaterials()
        findMaterialsUsage()
    }, [])

    const translateSearch = () => {
        let eSrarchs = document.getElementsByClassName("search-input");
        let i;
        for (i = 0; i < eSrarchs.length; i++) {
            eSrarchs[i].placeholder = "Procurar...";
        }
    }

    const changeSelectedMaterial = (item) => {
        setSelectedMaterial(item)
    }

    const updateMaterial = async () => {
        let material = document.getElementById("edtMaterialUpdate").value
        let description = document.getElementById("edtDescriptionUpdate").value
        if (material == '' || description == '') {
            setOverMessage({
                show: true,
                message: 'Favor informar o nome do material e a descrição',
                type: 'warning'
            })
            setTimeout(() => {
                setOverMessage({
                    show: false,
                    message: '',
                    type: ''
                })
            }, 4000)
        } else {
            try {
                setIsLoading(true)
                setOverMessage({
                    show: false,
                    message: '',
                    type: ''
                })

                if (selectedMaterial.id > 0) {
                    const response = await rest.post('/material/v1/update', {
                        id: selectedMaterial.id,
                        name: material,
                        description: description
                    })

                    // document.getElementById("edtCityUpdate").value = ''
                    // document.getElementById("edtUfUpdate").value = ''
                    await findMaterials()
                    document.getElementById('btnCloseModalId').click()
                    setOverMessage({
                        show: true,
                        message: 'Material alterado com sucesso',
                        type: 'success'
                    })
                    setIsLoading(false)
                    setTimeout(() => {
                        setOverMessage({
                            show: false,
                            message: '',
                            type: ''
                        })
                    }, 3000)
                } else {
                    setOverMessage({
                        show: false,
                        message: 'Favor selecionar um material',
                        type: 'warning'
                    })
                }
                setIsLoading(false)

            } catch (err) {
                setOverMessage({
                    show: true,
                    message: 'Falha o material',
                    type: 'error'
                })
                setIsLoading(false)
            }
        }
    }

    useEffect(() => {
        translateSearch()
        setSelectedMaterial(null)
    }, [materials])

    const filterUpdated = (newData, filterConfiguration) => {
        // setFilterData(newData)
        setMaterials(newData)
    }

    const filterUpdatedMaterialUsage = (newData, filterConfiguration) => {
        // setFilterData(newData)
        setMaterialsUsage(newData)
    }

    return (
        <div>
            {
                isLoading && <LoadingBar />
            }
            <div id="top">
                <MenuBar />
            </div>
            <OverMessage show={overMessage.show} message={overMessage.message} type={overMessage.type} />
            <div className="full padding100Top container">
                <div className="row">
                    <div className="offset-lg-4 col-12 col-lg-10">
                        <div className="row">
                            <div className="col-12 col-md-3 top10" onKeyDown={e => pressEnter(e)}>
                                <FieldText id="edtMaterial" name="edtMaterial" placeholder="Material" />
                            </div>
                            <div className="col-12 col-md-3 top10" onKeyDown={e => pressEnter(e)}>
                                <FieldText id="edtDescription" name="edtDescription" placeholder="Descrição" />
                            </div>
                            <div className="col-12 col-md-3 top10">
                                <div onClick={e => registerMaterial()}>
                                    <ButtonRectangle label="Cadastrar" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row top40">
                    <div className="col-12">
                        {/* {
                            materials && <CSVLink
                                filename='arquivo.csv'
                                className="btn btn-primary mainColorBk"
                                data={materials}>
                                Download
                            </CSVLink>
                        } */}
                    </div>
                    <div className="col-12">
                        <table className="table boxShadow table-hover borderRadius10">
                            <thead>
                                {
                                    materials &&
                                    <TableFilter
                                        rows={materials}
                                        onFilterUpdate={filterUpdated}
                                    >
                                        <th key="id" filterkey="id" className="cell" casesensitive={'true'} showsearch={'true'}>
                                            Código
                                        </th>
                                        <th filterkey="name">
                                            Material
                                        </th>
                                        <th filterkey="description">
                                            Descrição
                                        </th>
                                        <th></th>
                                    </TableFilter >
                                }
                            </thead>
                            <tbody>
                                {
                                    materials &&
                                    materials.map((item, key) => (
                                        <tr key={key}>
                                            <td>{item.id}</td>
                                            <td>{item.name}</td>
                                            <td>{item.description}</td>
                                            <td>
                                                <button data-toggle="modal" data-target='#updateMaterialModal' className='btn btn-primary mainColorBk' onClick={(e) => changeSelectedMaterial(item)}>Editar</button>
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                    <div class="modal fade" id='updateMaterialModal' tabindex="-1" role="dialog" aria-labelledby="updateMaterialModal" aria-hidden="true">
                        <div class="modal-dialog" role="document">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title" id="modalLongTitle">Editar Material</h5>
                                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div class="modal-body">
                                    {
                                        selectedMaterial &&
                                        <div className="row">
                                            <div className="col-12 col-md-3 top10" onKeyDown={e => pressEnter(e)}>
                                                <FieldText id="edtMaterialUpdate" name="edtMaterialUpdate" placeholder="Material" value={selectedMaterial ? selectedMaterial.name : ''} />
                                            </div>
                                            <div className="col-12 col-md-3 top10" onKeyDown={e => pressEnter(e)}>
                                                <FieldText id="edtDescriptionUpdate" name="edtDescriptionUpdate" placeholder="Description" value={selectedMaterial ? selectedMaterial.description : ''} />
                                            </div>
                                            <div className="col-12 col-md-3 top10">
                                                <div onClick={e => updateMaterial()}>
                                                    <ButtonRectangle label="Salvar" />
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                                <div class="modal-footer">
                                    <button id='btnCloseModalId' type="button" class="btn btn-secondary" data-dismiss="modal">Fechar</button>
                                    {/* <button type="button" class="btn btn-primary">Save changes</button> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>










            <div className="container">
                <div className="col-12 text-center bottomBlueBorder">
                    <p className="title">Relatório do uso de materiais</p>
                </div>
                <div className="col-12">
                    {
                        materialsUsage && <div>
                            {/* <CSVLink
                                filename='arquivo.csv'
                                className="btn btn-primary margin5 mainColorBk"
                                data={materialsUsage}>
                                CSV
                            </CSVLink> */}
                            <ReactHTMLTableToExcel
                                id="test-table-xls-button"
                                className="btn btn-primary margin5 mainColorBk"
                                table="table-to-xls"
                                filename="usoDeMateriais"
                                sheet="materiais"
                                buttonText="Download" />
                        </div>
                    }
                </div>
                <div className="col-12">
                    <table id="table-to-xls" className="table boxShadow table-hover borderRadius10">
                        <thead>
                            {
                                materialsUsage &&
                                <TableFilter
                                    rows={materialsUsage}
                                    onFilterUpdate={filterUpdatedMaterialUsage}
                                >
                                    <th filterkey="material" key="material" filterkey="material" className="cell" casesensitive={'true'} showsearch={'true'}>
                                        Material
                                    </th>
                                    <th filterkey="city">
                                        Cidade
                                    </th>
                                    <th filterkey="uf">
                                        UF
                                    </th>
                                    <th filterkey="finishDateBR">
                                        Data de conclusão
                                    </th>
                                    <th filterkey="quantity">
                                        Quantidade
                                    </th>
                                </TableFilter >
                            }
                        </thead>
                        <tbody>
                            {
                                materialsUsage &&
                                materialsUsage.map((item, key) => (
                                    <tr key={key}>
                                        <td>{item.material}</td>
                                        <td>{item.city}</td>
                                        <td>{item.uf}</td>
                                        <td>{item.finishDateBR}</td>
                                        <td>{item.quantity}</td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
            </div>



            <Footer />
        </div >
    )
}