import React from 'react';
import './styles.css'

function LoadingBar(props) {
    return (
        <div className="full blackMask06 over center">
           <div class="imageLoading"></div>
        </div>

    );
}

export default LoadingBar