import React, { useState, useEffect } from 'react'

//Service
import rest from '../../service/rest'

//Components
import MenuBar from '../../components/menuBar'
import Footer from '../../components/footer'
import LoadingBar from '../../components/loadingBar'
import AlertMessage from '../../components/alertMessage'
import FieldText from '../../components/fieldText'
import ButtonRectangle from '../../components/buttonRectangle'
import IconCard from '../../components/iconCard'
import FieldTextOpacity from '../../components/fieldTextOpacity'
import OverMessage from '../../components/overMessage'

//PrimeReact
import { Chart } from 'primereact/chart';

//Other
import { CSVLink, CSVDownload } from 'react-csv'
import TableFilter from 'react-table-filter'

export default () => {
    const [isLoading, setIsLoading] = useState(false)
    const [problems, setProblems] = useState()
    const [filterData, setFilterData] = useState()
    const [selectedProblem, setSelectedProblem] = useState()

    const [overMessage, setOverMessage] = useState({
        show: false,
        message: '',
        type: ''
    })

    const findProblems = async () => {
        const response = await rest.get('/problem/v1/findAll')
        setProblems(response.data)
    }

    const registerProblem = async () => {
        let problem = document.getElementById("edtProblem").value
        if (problem == '') {
            setOverMessage({
                show: true,
                message: 'Favor informar o problema',
                type: 'warning'
            })
            setTimeout(() => {
                setOverMessage({
                    show: false,
                    message: '',
                    type: ''
                })
            }, 4000)
        } else {
            try {
                setIsLoading(true)
                setOverMessage({
                    show: false,
                    message: '',
                    type: ''
                })

                const response = await rest.post('/problem/v1/save', {
                    problem: problem
                })

                document.getElementById("edtProblem").value = ''
                await findProblems()
                setIsLoading(false)

            } catch (err) {
                setOverMessage({
                    show: true,
                    message: 'Falha na autenticação',
                    type: 'error'
                })
                setIsLoading(false)
            }
        }
    }

    const updateProblem = async () => {
        let problem = document.getElementById("edtProblemUpdate").value
        if (problem == '') {
            setOverMessage({
                show: true,
                message: 'Favor informar o problema',
                type: 'warning'
            })
            setTimeout(() => {
                setOverMessage({
                    show: false,
                    message: '',
                    type: ''
                })
            }, 4000)
        } else {
            try {
                setIsLoading(true)
                setOverMessage({
                    show: false,
                    message: '',
                    type: ''
                })

                if (selectedProblem.id > 0) {
                    const response = await rest.post('/problem/v1/update', {
                        id: selectedProblem.id,
                        problem: problem
                    })

                    // document.getElementById("edtCityUpdate").value = ''
                    // document.getElementById("edtUfUpdate").value = ''
                    await findProblems()
                    document.getElementById('btnCloseModalId').click()
                    setOverMessage({
                        show: true,
                        message: 'Problema alterado com sucesso',
                        type: 'success'
                    })
                    setIsLoading(false)
                    setTimeout(() => {
                        setOverMessage({
                            show: false,
                            message: '',
                            type: ''
                        })
                    }, 3000)
                } else {
                    setOverMessage({
                        show: false,
                        message: 'Favor selecionar um problema',
                        type: 'warning'
                    })
                }
                setIsLoading(false)

            } catch (err) {
                setOverMessage({
                    show: true,
                    message: 'Falha ao alterar o problema',
                    type: 'error'
                })
                setIsLoading(false)
            }
        }
    }

    const pressEnter = async (e) => {
        if (e.key == 'Enter')
            registerProblem()
    }

    useEffect(() => {
        translateSearch()
        findProblems()
    }, [])

    const translateSearch = () => {
        let eSrarchs = document.getElementsByClassName("search-input");
        let i;
        for (i = 0; i < eSrarchs.length; i++) {
            eSrarchs[i].placeholder = "Procurar...";
        }
    }

    const changeSelectedProblem = (item) => {
        setSelectedProblem(item)
    }

    useEffect(() => {
        translateSearch()
        setSelectedProblem(null)
    }, [problems])

    const filterUpdated = (newData, filterConfiguration) => {
        // setFilterData(newData)
        setProblems(newData)
    }

    return (
        <div>
            {
                isLoading && <LoadingBar />
            }
            <div id="top">
                <MenuBar />
            </div>
            <OverMessage show={overMessage.show} message={overMessage.message} type={overMessage.type} />
            <div className="full padding100Top container">
                <div className="row">
                    <div className="offset-lg-4 col-12 col-lg-10">
                        <div className="row">
                            <div className="col-12 col-md-3 top10" onKeyDown={e => pressEnter(e)}>
                                <FieldText id="edtProblem" name="edtProblem" placeholder="Problema" />
                            </div>
                            <div className="col-12 col-md-3 top10">
                                <div onClick={e => registerProblem()}>
                                    <ButtonRectangle label="Cadastrar" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row top40">
                    <div className="col-12">
                        {
                            problems && <CSVLink
                                filename='arquivo.csv'
                                className="btn btn-primary mainColorBk"
                                data={problems}>
                                Download
                            </CSVLink>
                        }
                    </div>
                    <div className="col-12">
                        <table className="table boxShadow table-hover borderRadius10">
                            <thead>
                                {
                                    problems &&
                                    <TableFilter
                                        rows={problems}
                                        onFilterUpdate={filterUpdated}
                                    >
                                        <th key="id" filterkey="id" className="cell" casesensitive={'true'} showsearch={'true'}>
                                            Código
                                        </th>
                                        <th filterkey="problem">
                                            Problema
                                        </th>
                                        <th></th>
                                    </TableFilter >
                                }
                            </thead>
                            <tbody>
                                {
                                    problems &&
                                    problems.map((item, key) => (
                                        <tr key={key}>
                                            <td>{item.id}</td>
                                            <td>{item.problem}</td>
                                            <td>
                                                <button data-toggle="modal" data-target='#updateProblemModal' className='btn btn-primary mainColorBk' onClick={(e) => changeSelectedProblem(item)}>Editar</button>
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="modal fade" id='updateProblemModal' tabindex="-1" role="dialog" aria-labelledby="updateProblemModal" aria-hidden="true">
                    <div class="modal-dialog" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="modalLongTitle">Editar Problema</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                {
                                    selectedProblem &&
                                    <div className="row">
                                        <div className="col-12 col-md-3 top10" onKeyDown={e => pressEnter(e)}>
                                            <FieldText id="edtProblemUpdate" name="edtProblemUpdate" placeholder="Problema" value={selectedProblem ? selectedProblem.problem : ''} />
                                        </div>
                                        <div className="col-12 col-md-3 top10">
                                            <div onClick={e => updateProblem()}>
                                                <ButtonRectangle label="Salvar" />
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                            <div class="modal-footer">
                                <button id='btnCloseModalId' type="button" class="btn btn-secondary" data-dismiss="modal">Fechar</button>
                                {/* <button type="button" class="btn btn-primary">Save changes</button> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div >
    )
}