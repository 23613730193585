import React, { Component, useState } from 'react'
import { useHistory } from "react-router"

//css
import './styles.css'

//Service
import rest from '../../service/rest'

//Components
import LoadingBar from '../../components/loadingBar'
import AlertMessage from '../../components/alertMessage'
import FieldTextOpacity from '../../components/fieldTextOpacity'
import ButtonRectangle from '../../components/buttonRectangle'
import Footer from '../../components/footer'

export default function Login() {
    let history = useHistory()
    const [isOnline, setOnline] = useState(window.navigator.onLine)
    const [isLoading, setIsLoading] = useState(false)
    const [alertMessage, setAlertMessage] = useState({
        type: '',
        msg: '',
        show: false
    })

    const pressEnter = async (e) => {
        if (e.key == 'Enter')
            signIn()
    }

    const signIn = async () => {
        let username = document.getElementById("edtUserName").value
        let password = document.getElementById("edtPassword").value
        if (username == '' || password == '') {
            setAlertMessage({
                type: 'error',
                msg: 'Favor informar usuário e senha',
                show: true
            })
        } else {
            try {
                setIsLoading(true)
                setAlertMessage({
                    type: '',
                    msg: '',
                    show: false
                })

                const response = await rest.post('/login/v1/login', {
                    username: username,
                    password: password
                })

                // const token = response.headers.authorization;
                const user = response.data
                setIsLoading(false)

                // await localStorage.setItem('@lighthouse_jjeletrica:token', token)
                await localStorage.setItem('@lighthouse_jjeletrica:user', JSON.stringify(response.data))
                if (user && user.token) {
                    history.push('/home')
                } else {
                    setAlertMessage({
                        type: 'error',
                        msg: 'Usuário ou senha inválidos',
                        show: true
                    })
                }
            } catch (err) {
                setAlertMessage({
                    type: 'error',
                    msg: 'Falha na autenticação',
                    show: true
                })
                setIsLoading(false)
            }
        }
    }

    return (
        <div>
            {
                isLoading ?
                    <LoadingBar />
                    :
                    <div></div>
            }
            <div>
                <div className="full loginBackground whiteFont">
                    <div className="full blackMask06 center">
                        <div className="row boxBackground padding20 col-12 col-sm-6 col-lg-3">
                            <div className="col-12 text-center">
                                <h1 className="title padding30">Login</h1>
                            </div>
                            <div className="col-12">
                                {
                                    alertMessage.show ?
                                        <AlertMessage msg={alertMessage.msg} type={alertMessage.type} />
                                        :
                                        <div></div>
                                }
                            </div>
                            <div className="col-12">
                                <FieldTextOpacity id="edtUserName" name="edtUserName" placeholder="Usuário" />
                            </div>
                            <div className="col-12 top10" onKeyDown={e => pressEnter(e)}>
                                <FieldTextOpacity id="edtPassword" name="edtPassword" type="password" placeholder="Senha" />
                            </div>
                            <div className="col-12 center top40 bottom30">
                                <div onClick={e => signIn()}>
                                    <ButtonRectangle label="LOGIN" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}