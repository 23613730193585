import React, { Component } from 'react'

//css
import './styles.css'

//Service
import rest from '../../service/rest'

//Components
import LoadingBar from '../../components/loadingBar'
import AlertMessage from '../../components/alertMessage'
import FieldTextOpacity from '../../components/fieldTextOpacity'
import ButtonRectangle from '../../components/buttonRectangle'
import Footer from '../../components/footer'

export default class Intro extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            alertMessage: {
                type: '',
                msg: '',
                show: false
            }
        }
    }

    pressEnter = async (e) => {
        if (e.key == 'Enter')
            this.signIn()
    }

    toLogin = async () => {
        this.props.history.push('/login')
    }

    toNewTask = async () => {
        this.props.history.push('/atendimento')
    }

    render() {
        return (
            <div>
                {
                    this.state.isLoading ?
                        <LoadingBar />
                        :
                        <div></div>
                }
                <div>
                    <div className="full introBackground whiteFont">
                        <div className="full blackMask06 center">
                            <div className="row boxBackground padding20 col-12 col-sm-6 col-lg-3">
                                <div className="col-12 text-center">
                                    <h1 className="title padding30">Bem-Vindo</h1>
                                </div>
                                <div className="col-12 center">
                                    <div onClick={e => this.toNewTask()}>
                                        <ButtonRectangle label="Registrar Atendimento" />
                                    </div>
                                </div>
                                <div className="col-12 center top10">
                                    <div onClick={e => this.toLogin()}>
                                        <ButtonRectangle label="Efetuar Login" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        )
    }
}