import React, { Component } from 'react';

import logoImg from '../../imgs/logojj.png'

export default class MenuBar extends Component {
    constructor(props) {
        super(props)
        this.state = {
            level: 0
        }
    }

    signOut = async () => {
        try {
            //Logout
            // await localStorage.removeItem('@lighthouse_jjeletrica:token', '');
            await localStorage.removeItem('@lighthouse_jjeletrica:user', '');
            window.location.reload(false)

            //Temporário
            window.location.pathname = "/login"

        } catch (err) {
        }
    }

    async componentDidMount() {
        const userFromStorage = await localStorage.getItem('@lighthouse_jjeletrica:user')
        const user = JSON.parse(userFromStorage)
        // const user = localStorage.getItem('@lighthouse_jjeletrica:user')
        if (user) {
            this.setState({
                ...this.state,
                level: user.level
            })
        }
    }

    render() {
        return (
            <div>
                <nav id="menuFixed" className="navbar fixed-top navbar-expand-lg nav-light menu_nav_bar delay">
                    <a className="navbar-brand" href="#top"><img src={logoImg} className="logoImg" /></a>
                    <button className="navbar-toggler blueMask01" type="button" data-toggle="collapse" data-target="#menuBar" aria-controls="menuBar" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon center topLess1">-</span>
                    </button>

                    <div className="collapse navbar-collapse" id="menuBar">
                        <ul className="navbar-nav ml-auto">
                            <li className="nav-item active menu_options">
                                <a className="nav-link" href="/home">Inicio</a>
                            </li>
                            <li className="nav-item active menu_options">
                                <a className="nav-link" href="/chamados">Chamados</a>
                            </li>
                            {
                                this.state.level == 3 &&
                                <>
                                    <li className="nav-item active menu_options">
                                        <a className="nav-link" href="/material ">Materiais</a>
                                    </li>
                                    <li className="nav-item active menu_options">
                                        <a className="nav-link" href="/usuarios">Acessos</a>
                                    </li>
                                    <li className="nav-item active menu_options">
                                        <a className="nav-link" href="/cidades">Cidades</a>
                                    </li>
                                    <li className="nav-item active menu_options">
                                        <a className="nav-link" href="/problema">Problemas</a>
                                    </li>
                                    <li className="nav-item active menu_options">
                                        <a className="nav-link" href="/pdf">PDF</a>
                                    </li>
                                </>
                            }


                            <li className="nav-item dropdown menu_options">
                                <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    Opções
                                </a>
                                <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                    <a className="dropdown-item" onClick={e => this.signOut()}>Sair</a>
                                </div>
                            </li>
                        </ul>
                    </div>
                </nav>
            </div>

        )
    }
}