import React from 'react'
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'
import isAuthenticated from './config/service/auth'
import isAdmAuthenticated from './config/service/authAdm'
import HelpDesk from './config/pages/helpDesk'
import Intro from './config/pages/intro'
import Login from './config/pages/login'
import Tasks from './config/pages/tasks'
import Home from './config/pages/home'
import Users from './config/pages/users'
import Material from './config/pages/material'
import Task from './config/pages/task'
import Problem from './config/pages/problem'
import Citys from './config/pages/citys'
import PDFPage from './config/pages/pdf'

const AssignRoute = ({ component: Component, ...rest }) => (
    <Route
        {...rest}
        render={
            props => isAuthenticated() ? (
                <Component {...props} />
            )
                :
                (
                    <Redirect to={{ pathname: "/login", state: { from: props.location } }} />
                )
        }
    />
)

const AssignAdmRoute = ({ component: Component, ...rest }) => (
    <Route
        {...rest}
        render={
            props => isAdmAuthenticated() ? (
                <Component {...props} />
            )
                :
                (
                    <Redirect to={{ pathname: "/home", state: { from: props.location } }} />
                )
        }
    />
)

const Routes = () => (
    <BrowserRouter>
        <Switch>
            <AssignRoute exact path="/home" component={Home} />
            <AssignRoute exact path="/chamados" component={Tasks} />
            <AssignAdmRoute exact path="/usuarios" component={Users} />
            <AssignAdmRoute exact path="/material" component={Material} />
            <AssignAdmRoute exact path="/problema" component={Problem} />
            <AssignAdmRoute exact path="/cidades" component={Citys} />
            <AssignRoute exact path="/chamado" component={Task} />
            <Route exact path="/atendimento" component={HelpDesk} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/pdf" component={PDFPage} />
            <Route component={Intro} />
        </Switch>
    </BrowserRouter>
)

export default Routes