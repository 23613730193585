import React from 'react';

function ButtonRectangle(props) {
    return (
        <div>
            <button type="button" className="btn btn-primary btn-block padding10 delay">
                <div>
                    {props.label}
                </div>
            </button>
        </div>

    );
}

export default ButtonRectangle;