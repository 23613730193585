import React, { useState, useEffect } from 'react'

//css
import './styles.css'

//Service
import rest from '../../service/rest'

//Components
import MenuBar from '../../components/menuBar'
import Footer from '../../components/footer'
import LoadingBar from '../../components/loadingBar'
import AlertMessage from '../../components/alertMessage'
import FieldText from '../../components/fieldText'
import ButtonRectangle from '../../components/buttonRectangle'
import IconCard from '../../components/iconCard'
import FieldTextOpacity from '../../components/fieldTextOpacity'
import OverMessage from '../../components/overMessage'
import TableFilter from 'react-table-filter'

//PrimeReact
import { Chart } from 'primereact/chart';

export default () => {
    const [isLoading, setIsLoading] = useState(false)
    const [users, setUsers] = useState()
    const [overMessage, setOverMessage] = useState({
        show: false,
        message: '',
        type: ''
    })

    const findUsers = async () => {
        const response = await rest.get('login/v1/findAll')
        setUsers(response.data)
    }

    const registerUser = async () => {
        let username = document.getElementById("edtLogin").value
        let password = document.getElementById("edtPassword").value
        let admCheckBox = document.getElementById("admCheckBox").checked
        if (username == '' || password == '') {
            setOverMessage({
                show: true,
                message: 'Favor informar usuário e senha',
                type: 'warning'
            })
            setTimeout(() => {
                setOverMessage({
                    show: false,
                    message: '',
                    type: ''
                })
            }, 4000)
        } else {
            try {
                setIsLoading(true)
                setOverMessage({
                    show: false,
                    message: '',
                    type: ''
                })

                const response = await rest.post('/login/v1/registeruser', {
                    username: username,
                    password: password,
                    isAdmin: admCheckBox ? true : false,
                    level: admCheckBox ? 3 : 2
                    // accessLevel: admCheckBox ? { id: 3 } : { id: 2 }
                })

                document.getElementById("edtLogin").value = ''
                document.getElementById("edtPassword").value = ''
                document.getElementById("admCheckBox").checked = false
                await findUsers()
                setIsLoading(false)

                setOverMessage({
                    show: true,
                    message: 'Usuário registrado com sucesso',
                    type: 'success'
                })

                setTimeout(() => {
                    setOverMessage({
                        show: false,
                        message: '',
                        type: ''
                    })
                }, 2000)

            } catch (err) {
                setOverMessage({
                    show: true,
                    message: 'Falha na autenticação',
                    type: 'error'
                })
                setIsLoading(false)
            }
        }
    }

    const pressEnter = async (e) => {
        if (e.key == 'Enter')
            registerUser()
    }

    const filterUpdated = (newData, filterConfiguration) => {
        setUsers(newData)
    }

    useEffect(() => {
        findUsers()
    }, [])

    return (
        <div>
            {
                isLoading && <LoadingBar />
            }
            <div id="top">
                <MenuBar />
            </div>
            <OverMessage show={overMessage.show} message={overMessage.message} type={overMessage.type} />
            <div className="full padding100Top container">
                <div className="row">
                    <div className="offset-lg-4 col-12 col-lg-10">
                        <div className="row">
                            <div className="col-12 col-md-2 top10 center">
                                <div class="form-check">
                                    <input type="checkbox" class="form-check-input" id="admCheckBox" />
                                    <label class="form-check-label" for="admCheckBox">Administrador</label>
                                </div>
                            </div>
                            <div className="col-12 col-md-3 top10">
                                <FieldText id="edtLogin" name="edtLogin" placeholder="Login" />
                            </div>
                            <div className="col-12 col-md-3 top10" onKeyDown={e => pressEnter(e)}>
                                <FieldText id="edtPassword" name="edtPassword" type="password" placeholder="Senha" />
                            </div>
                            <div className="col-12 col-md-3 top10">
                                <div onClick={e => registerUser()}>
                                    <ButtonRectangle label="Cadastrar/Alterar" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row top40">
                    <div className="col-12">
                        <table className="table boxShadow table-hover borderRadius10">
                            <thead>
                                {
                                    users &&
                                    <TableFilter
                                        rows={users}
                                        onFilterUpdate={filterUpdated}
                                    >
                                        <th key="username" filterkey="username" className="cell" casesensitive={'true'} showsearch={'true'}>
                                            Login
                                        </th>
                                        <th>
                                            Senha
                                        </th>
                                        {/* <th filterkey="isAdmin"> */}
                                        <th>
                                            Administrador
                                        </th>
                                    </TableFilter >
                                }
                            </thead>
                            <tbody>
                                {
                                    users &&
                                    users.map((item, key) => (
                                        <tr key={key}>
                                            <td>{item.username}</td>
                                            <td>******</td>
                                            <td>{item.isAdmin ? '*' : ''}</td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}