import React, { useState, useEffect } from 'react'

//css
import './styles.css'

//Service
import rest from '../../service/rest'

//Components
import MenuBar from '../../components/menuBar'
import Footer from '../../components/footer'
import LoadingBar from '../../components/loadingBar'
import AlertMessage from '../../components/alertMessage'
import FieldText from '../../components/fieldText'
import ButtonRectangle from '../../components/buttonRectangle'
import IconCard from '../../components/iconCard'
import FieldTextOpacity from '../../components/fieldTextOpacity'
import OverMessage from '../../components/overMessage'

//PrimeReact
import { Chart } from 'primereact/chart';

export default () => {
    const [isLoading, setIsLoading] = useState(false)
    const [helpdeskStatus, setHelpdeskStatus] = useState()
    const [overMessage, setOverMessage] = useState({
        show: false,
        message: '',
        type: ''
    })

    const [containsStatusSTR, setContainsStatusSTR] = useState('')

    const [helpDesks, setHelpDesks] = useState({
        labels: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
        datasets: [
            {
                label: 'Atendimentos Registrados',
                data: [65, 59, 80, 81, 56, 55, 40],
                fill: false,
                backgroundColor: '#42A5F5',
                borderColor: '#42A5F5'
            },
            {
                label: 'Atendimentos Concluídos',
                data: [28, 48, 40, 19, 86, 27, 90],
                fill: false,
                backgroundColor: '#66BB6A',
                borderColor: '#66BB6A'
            }
        ]
    })

    const findHelpDesk = async () => {
        const response = await rest.get('/view/v1/findStatusView')
        setHelpdeskStatus(response.data)
    }

    useEffect(() => {
        findHelpDesk()
    }, [])

    useEffect(() => {
        if (helpdeskStatus) {
            let str = ''
            helpdeskStatus.forEach(element => {
                str += ',' + element[2]
            })
            setContainsStatusSTR(str)
        }
    }, [helpdeskStatus])

    return (
        <div>
            {
                isLoading && <LoadingBar />
            }
            <div id="top">
                <MenuBar />
            </div>
            <div className="full padding100Top container">
                <div className="row">
                    <div className="col-12">
                        <div className="row">
                            <div className="col-12 col-md-2">
                            </div>
                            <div className="col-12 col-md-10 center">
                                <div className="row">
                                    {
                                        helpdeskStatus &&
                                        helpdeskStatus.map((item, key) => (
                                            <div className="col-12 col-md-4 padding20" key={key}>
                                                <IconCard type={item.status} title={item.status + 's'} info={item.quantity} obs="" />
                                            </div>
                                        ))
                                    }

                                    {/* {
                                        helpdeskStatus &&
                                        helpdeskStatus.map((item, key) => (
                                            <div className="col-12 col-md-4 padding20" key={key}>
                                                <IconCard type={item[2]} title={item[2] + 's'} info={item[0]} obs="" />
                                            </div>
                                        ))
                                    }

                                    {
                                        containsStatusSTR.includes('Aberto') ? null :
                                            <div className="col-12 col-md-4 padding20">
                                                <IconCard type="Aberto" title="Abertos" info={0} obs="" />
                                            </div>
                                    }

                                    {
                                        containsStatusSTR.includes('Concluído') ? null :
                                            <div className="col-12 col-md-4 padding20">
                                                <IconCard type="Concluído" title="Concluidos" info="0" obs="" />
                                            </div>
                                    }
                                    {
                                        containsStatusSTR.includes('Atrasado') ? null :
                                            <div className="col-12 col-md-4 padding20">
                                                <IconCard type="Atrasado" title="Atrasados" info="0" obs="" />
                                            </div>
                                    } */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="row top40">
                    <div className="col-12 col-md-6 boxShadowHover delay2">
                        <Chart type="line" data={helpDesks} />
                    </div>
                    <div className="col-12 col-md-6 boxShadowHover delay2">
                        <Chart type="bar" data={helpDesks} />
                    </div>
                </div> */}
            </div>
            <Footer />
        </div>
    )
}