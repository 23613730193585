import React, { useEffect, useState } from 'react'
import Loading from '../../components/loadingBar'
import rest from '../../service/restAuth'
import Footer from '../../components/footer'
import { CSVLink, CSVDownload } from 'react-csv'
import LoadingBar from '../../components/loadingBar'
import MenuBar from '../../components/menuBar'
import TableFilter from 'react-table-filter'
import { Link } from 'react-router-dom'
import task from '../task'

export default () => {
    const [isLoading, setIsLoading] = useState(false)
    const [tasks, setTasks] = useState()
    const [csvData, setCsvData] = useState([])

    const findTasks = async () => {
        try {
            setIsLoading(true)
            const response = await rest.get('/helpdesk/v1/findAll')
            setTasks(response.data)
            setIsLoading(false)
        } catch (e) {
            setIsLoading(false)
        }
    }

    window.onscroll = function (oEvent) {
        checkHeadTable()
    }

    const checkHeadTable = () => {
        if (window.pageYOffset >= 100) {
            // document.getElementById('headLineTableId').classList.add('topFixed')
        } else {
            // document.getElementById('headLineTableId').classList.remove('topFixed')
        }
    }

    const filterUpdated = (newData, filterConfiguration) => {
        setTasks(newData)
    }

    useEffect(() => {
        findTasks()
    }, [])

    return (
        <div>
            {
                isLoading && <LoadingBar />
            }
            <div id="top">
                <MenuBar />
            </div>
            <div className="full padding100Top container">
                <div className="row top40">
                    <div className="col-12">
                        <div className='row'>
                            <div className='padding04'>
                                {
                                    tasks &&
                                    <CSVLink
                                        filename='arquivo.csv'
                                        className="btn btn-primary mainColorBk"
                                        data={tasks}>
                                        Download CSV
                                    </CSVLink>
                                }
                            </div>
                            <div className='padding04'>
                                {/* {
                                    tasks &&
                                    <form id={`pdfGeneratorFormAll`} action="pdfGenerator.php" method="post">
                                        <input type="hidden" name="tasks" value={JSON.stringify(tasks)} />
                                        <input type="submit" value='Download PDFs' className='btn btn-primary' />
                                    </form>
                                } */}
                            </div>
                        </div>
                    </div>
                    <div className="col-12">
                        <table className="table boxShadow table-hover borderRadius10">
                            <thead>
                                {
                                    tasks &&
                                    <TableFilter
                                        rows={tasks}
                                        onFilterUpdate={filterUpdated}
                                    >
                                        <th key="protocol" filterkey="protocol" className="cell" casesensitive={'true'} showsearch={'true'}>
                                            Protocolo
                                        </th>
                                        <th filterkey="statusHelpDesk.status">
                                            Status
                                        </th>
                                        <th filterkey="address.city.name">
                                            Cidade
                                        </th>
                                        <th filterkey="address.neighborhood">
                                            Bairro
                                        </th>
                                        <th filterkey="address.street">
                                            Rua
                                        </th>
                                        <th filterkey="openedDateBR">
                                            Data de Abertura
                                        </th>
                                        <th filterkey="finishDateBR">
                                            Data de Atendimento
                                        </th>
                                        <th>
                                            Detalhes
                                        </th>
                                        <th>
                                            PDF
                                        </th>
                                    </TableFilter >
                                }
                            </thead>
                            <tbody>
                                {
                                    tasks &&
                                    tasks.map((item, key) => (
                                        <tr key={key}>
                                            <td>{item.protocol}</td>
                                            <td>{item.statusHelpDesk.status}</td>
                                            <td>{item.address.city.name}</td>
                                            <td>{item.address.neighborhood}</td>
                                            <td>{item.address.street}</td>
                                            <td>{item.openedDateBR}</td>
                                            <td>{item.finishDateBR}</td>
                                            <td>
                                                <Link
                                                    to={{
                                                        pathname: "/chamado",
                                                        helpDeskID: item.id
                                                    }}
                                                >
                                                    <button className='btn btn-primary mainColorBk'>Detalhes</button>
                                                </Link>
                                            </td>
                                            <td>
                                                <form id={`pdfGeneratorForm${item.protocol}`} action="pdfGenerator.php" method="post">
                                                    <input type="hidden" name="tasks" value={'[' + JSON.stringify(item) + ']'} />
                                                    <input type="submit" value='Download PDF' className='btn btn-primary' />
                                                </form>
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}