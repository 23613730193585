import React, { useState, useEffect } from 'react'
import rest from '../../service/restAuth'
import LoadingBar from '../../components/loadingBar'
import MenuBar from '../../components/menuBar'
import Footer from '../../components/footer'
import TableFilter from 'react-table-filter'

const PDFPage = () => {
    const [isLoading, setIsLoading] = useState(false)
    const [pageHTML, setPageHTML] = useState('')
    const [tasks, setTasks] = useState()

    const sendData = () => {
        // const server = 'http://localhost/gerarpdf/pdfGenerator.php'
        // const server = '../../php/pdfGenerator.php'
        // const formData = new FormData()
        // let page = document.getElementById('page')
        // formData.append('page', page)
        // fetch(server, {
        //     method: 'post',
        //     body: formData
        // }).then((res) => {
        // }).catch((error) => {
        // })

        tasks.forEach(t => {
            setTimeout(() => {
                document.getElementById('task').value = '[' + JSON.stringify(t) + ']'
                document.getElementById("pdfGeneratorForm").submit();
            }, 100)
        })

    }

    const findTasks = async () => {
        try {
            const response = await rest.get(`/helpdesk/v1/findAll`)
            setTasks(response.data)
        } catch (e) {
        }
    }

    const filterUpdated = (newData, filterConfiguration) => {
        setTasks(newData)
    }

    useEffect(async () => {
        await findTasks()
        setPageHTML(document.getElementById('page'))
    }, [])

    useEffect(() => {
        let p = document.getElementById('page')
        setPageHTML(p)
    }, [tasks])

    return (
        <div>
            {
                isLoading && <LoadingBar />
            }
            <div id="top">
                <MenuBar />
            </div>
            <div className="full padding100Top container">
                {/* <button type="button" className="btn btn-primary" onClick={() => { sendData() }} >
                    <div>
                        Download PDF
                    </div>
                </button> */}
                {/* <form id='pdfGeneratorForm' action="pdfGenerator.php" method="post"> */}
                {/* <input type="text" name="page" value={pageHTML.outerHTML} /> */}
                {/* <input type="hidden" id="task" name="task" value='' /> */}
                {/* <input type="hidden" name="tasks" value={JSON.stringify(tasks)} /> */}
                {/* <input type="submit" value='Download PDF' className='btn btn-primary' /> */}
                {/* </form> */}
                <div className="col-12">
                    <table className="table boxShadow table-hover borderRadius10">
                        <thead>
                            {
                                tasks &&
                                <TableFilter
                                    rows={tasks}
                                    onFilterUpdate={filterUpdated}
                                >
                                    <th key="protocol" filterkey="protocol" className="cell" casesensitive={'true'} showsearch={'true'}>
                                        Protocolo
                                    </th>
                                    <th filterkey="statusHelpDesk.status">
                                        Status
                                    </th>
                                    <th filterkey="address.city.name">
                                        Cidade
                                    </th>
                                    <th filterkey="address.neighborhood">
                                        Bairro
                                    </th>
                                    <th filterkey="address.street">
                                        Rua
                                    </th>
                                    <th filterkey="openedDateBR">
                                        Data de Abertura
                                    </th>
                                    <th filterkey="finishDateBR">
                                        Data de Atendimento
                                    </th>
                                    <th>
                                        Detalhes
                                    </th>
                                </TableFilter >
                            }
                        </thead>
                        <tbody>
                            {
                                tasks &&
                                tasks.map((item, key) => (
                                    <tr key={key}>
                                        <td>{item.protocol}</td>
                                        <td>{item.statusHelpDesk.status}</td>
                                        <td>{item.address.city.name}</td>
                                        <td>{item.address.neighborhood}</td>
                                        <td>{item.address.street}</td>
                                        <td>{item.openedDateBR}</td>
                                        <td>{item.finishDateBR}</td>
                                        <td>
                                            <form id='pdfGeneratorForm' action="pdfGenerator.php" method="post">
                                                <input type="hidden" name="tasks" value={'[' + JSON.stringify(item) + ']'} />
                                                <input type="submit" value='Download PDF' className='btn btn-primary' />
                                            </form>
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default PDFPage